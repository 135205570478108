<template>
  <section class="apropos">
    <img
      class="img-contact"
      src="../assets/workhard.jpg"
      alt="image de fond"
      width="320"
      height="300"
    />
    <div class="bloc-apropos2">
      <div class="titre-apropos">
        <p class="text-p">A propos</p>
        <h1 class="text-h1">
          Laetitia Duchemin, développeur web à Toulon. <br />Création de sites
          internets et d'applications web, je propose mes services en tant que
          développeur web et rédactrice web.
        </h1>
      </div>
    </div>
    <div class="cards">
      <div class="cards-text">
        <div class="reconversion">
          <h2>Ma reconversion...</h2>
          <p>
            J’ai commencé mon parcours avec un diplôme en design, mais avec le
            manque d'opportunités professionnelles, je me suis orienté vers le
            commerce de la mode en tant qu'auto-entrepreneure puis dans la vente
            en boulangerie/pâtisserie.<br />
            J'étais déjà intéressée par le web depuis que j'ai commençé à
            faire du Blogging, puis, me passionnant de plus en plus par le
            Web Design, j'ai voulu créer mes propres sites, c'est ainsi que j'ai
            décidé de me reconvertir en passant un diplôme de Développeur Web
            chez Openclassrooms, pour enfin concilier création, développement et
            rédaction pour le web.
          </p>
        </div>
        <div class="photo-apropos">
          <img
            src="../assets/portrait.png"
            alt="portrait"
            width="158"
            height="292"
          />
        </div>
        <div class="dev-web">
          
            
          
          <div class="p-flex">
            <h2>...En tant que développeur web et rédactrice web.</h2>
            <p>
              Je crée des projets digitaux sous forme de site web ou
              d’application web.<br />
              Ma créativité et mon sens de l’organisation apportera à votre
              projet un site web responsive personnalisé, de qualité et
              tendance.
              <br />
              Je crée aussi du contenu web en écrivant des articles de blogs bien fournis avec des
              informations pertinentes.
              <br />
              Je travaille à Toulon et ses environs, mais aussi à distance.
              <br />
              <br />
              Pour plus d'informations me concernant :
              <span class="logos">
                <span title="Linkedin">
                  <a
                    href="https://www.linkedin.com/in/laetitia-duchemin/"
                    target="_blank"
                    aria-label="linkedin"
                  >
                    <fa :icon="['fab', 'linkedin']" class="link-linkedin" />
                  </a>
                </span>
                <span title="Facebook">
                  <a
                    href="https://www.facebook.com/laetitia.duchemin.dev/"
                    target="_blank"
                    aria-label="facebook"
                  >
                    <fa :icon="['fab', 'facebook']" class="link-facebook" />
                  </a>
                </span>
              </span>
            </p>
          </div>
        </div>
      </div>
      <router-link class="link-contact" to="/contact" aria-label="contact">
        <Button title="Contactez-moi" />
      </router-link>
    </div>
  </section>
</template>

<script>
import Button from "@/components/Button.vue";
export default {
  name: "Apropos",
  components: {
    Button,
  },
  mounted() {
    document.title = "A PROPOS | Laetitia Duchemin";
  },
};
</script>

<style lang="scss">
.reconversion,
.dev-web {
  margin: 2rem;
}
.titre-apropos {
  border-radius: 20px;
  margin-bottom: 4rem;
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.3);
  background-color: #fff;
}
.text-p {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 500;
  padding-top: 2rem;
  color: #f845c5;
}
.text-h1 {
  font-size: 1.2rem;
  padding: 2rem;
  line-height: 30px;
}
.reconversion h2,
.dev-web h2 {
  padding: 1rem 1rem 1rem 0;
  font-weight: 600;
  font-size: 1.2rem;
}
.dev-web{
  display: block;
  align-content: center;
}
.dev-web h2 {
  text-align: right;
}
.reconversion p,
.dev-web p {
  font-size: 1.2rem;
  line-height: 30px;
  text-align: justify;
}
.p-flex {
  display: block;
}
.p-flex p{
  text-align: justify;
  margin-top: 1rem;
}
.bloc-apropos2 {
  margin: -6rem 2rem 2rem 2rem;
  position: relative;
  z-index: 1;
}
.cards .link-contact {
  display: flex;
  justify-content: center;
  margin: 3rem 1rem 4rem 1rem;
  animation: smooth 1.5s;
}
.photo-apropos {
  background: #f5f5f5;
  clip-path: polygon(0% 0%, 60% 0%, 100% 100%, 0% 100%);
  order: 2;
  display: flex;
  grid-column-start: 1;
  grid-row-start: 2;
}
.photo-apropos img {
  position: relative;
  top: 0.5rem;
  margin: 5rem auto 0 auto;
  padding: 0;
  object-fit: cover;
}

.link-linkedin,
.link-facebook {
  color: #f845c5;
  font-size: 2rem;
  margin-left: 1rem;
  padding: 0;
}
.link-malt {
  margin-left: 1rem;
  padding: 0;
}
.link-linkedin:hover,
.link-malt:hover,
.link-facebook:hover {
  transform: scale(1.1);
  transition: all ease-in-out 0.1s;
}

@media screen and (min-width: 600px) {
  .titre-apropos {
    margin-left: 10%;
    margin-right: 10%;
  }
}
@media screen and (min-width: 768px) {
  .cards-text {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 2rem;
    gap: 2rem;
    margin: 2rem;
  }
  .bloc-apropos2 {
    margin-top: -6rem;
  }
  .reconversion {
    margin: 2rem 0;
    padding: 0;
    grid-column: 1 / span 2;
    grid-row-start: 1;
  }
  .photo-apropos img {
    margin-top: 12rem;
  }
  .dev-web {
    grid-column-start: 2;
    grid-row-start: 2;
    display: grid;
    justify-content: center;
    margin: 0;
  }
  .dev-web h2 {
    padding: 0;
  }
}
@media screen and (min-width: 1024px) {
  .cards {
    margin-left: 10%;
    margin-right: 10%;
  }
}
@media screen and (min-width: 1440px) {
  .bloc-apropos2 {
    margin-left: 10%;
    margin-right: 10%;
  }
}
@media screen and (min-width: 1770px) {
  .cards {
    margin-left: 15%;
    margin-right: 15%;
  }
}
</style>
