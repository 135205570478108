<template>
  <section class="confident">
    <img
      class="img-confident"
      src="../assets/legale.jpg"
      alt="image hero"
      width="220"
      height="300"
    />
    <div class="bloc-confident">
      <div class="titre-confident">
        <h1>Infos légales</h1>
        <p>
          Mentions légales - Politique de confidentialité - Conditions générales
          d'utilisation.
        </p>
      </div>
      <!-- Mentions légales -->
      <div id="bloc-mentions">
        <h2>Mentions légales</h2>
        <p>
          1. Présentation du site : <br /><br />
          Conformément aux dispositions des articles 6-III et 19 de la Loi n°
          2004-575 du 21 juin 2004 pour la Confiance dans l'économie numérique,
          dite L.C.E.N., nous portons à la connaissance des utilisateurs et
          visiteurs du site : laetitiaduchemin.fr les informations suivantes :
          <br /><br />
          Informations légales :
          <br /><br />
          Statut du propriétaire : auto-entrepreneur<br />
          <!-- Préfixe : micro-entreprise<br /> -->
          Nom de la Société : Laetitia Duchemin<br />
          Adresse : 286 Boulevard Bazeilles 83000 TOULON<br />
          Tél : 0680178054<br />
          <!-- Au Capital de : 100€<br /> -->
          SIRET : 792 175 754 00011 <br />
          <!-- Numéro TVA intracommunautaire : <br /> -->
          Adresse de courrier électronique : contact@laetitiaduchemin.fr
          <br /><br />
          Le Créateur du site est : Laetitia Duchemin<br />
          Le Responsable de la publication est : Laetitia Duchemin<br />
          Contactez le responsable de la publication :
          contact@laetitiaduchemin.fr<br />
          Le responsable de la publication est une personne physique ou morale
          <br /><br />
          Le Webmaster est : Laetitia Duchemin<br />
          Contactez le Webmaster : contact@laetitiaduchemin.fr<br />
          L’hébergeur du site est : O2Switch - 222 Boulevard Gustave Flaubert,
          63000 Clermont-Ferrand - Téléphone : 04.44.44.60.40
          <br /><br />
          2. Description des services fournis :
          <br /><br />
          Le site laetitiaduchemin.fr a pour objet de fournir une information
          concernant l’ensemble des activités de la société.<br />
          Le propriétaire du site s’efforce de fournir sur le site
          laetitiaduchemin.fr des informations aussi précises que possible.
          Toutefois, il ne pourra être tenue responsable des omissions, des
          inexactitudes et des carences dans la mise à jour, qu’elles soient de
          son fait ou du fait des tiers partenaires qui lui fournissent ces
          informations.<br />
          Tous les informations proposées sur le site laetitiaduchemin.fr sont
          données à titre indicatif, sont non exhaustives, et sont susceptibles
          d’évoluer. Elles sont données sous réserve de modifications ayant été
          apportées depuis leur mise en ligne.
          <br /><br />
          3. Propriété intellectuelle et contrefaçons :
          <br /><br />
          Le propriétaire du site est propriétaire des droits de propriété
          intellectuelle ou détient les droits d’usage sur tous les éléments
          accessibles sur le site, notamment les textes, images, graphismes,
          logo, icônes, sons, logiciels…<br />
          Toute reproduction, représentation, modification, publication,
          adaptation totale ou partielle des éléments du site, quel que soit le
          moyen ou le procédé utilisé, est interdite, sauf autorisation écrite
          préalable à l'email : contact@laetitiaduchemin.fr .<br />
          Toute exploitation non autorisée du site ou de l’un quelconque de ces
          éléments qu’il contient sera considérée comme constitutive d’une
          contrefaçon et poursuivie conformément aux dispositions des articles
          L.335-2 et suivants du Code de Propriété Intellectuelle.
          <br /><br />
          4. Liens hypertextes et cookies :
          <br /><br />
          Le site laetitiaduchemin.fr contient un certain nombre de liens
          hypertextes vers d’autres sites (partenaires, informations …) mis en
          place avec l’autorisation du propriétaire du site . Cependant, le
          propriétaire du site n’a pas la possibilité de vérifier le contenu des
          sites ainsi visités et décline donc toute responsabilité de ce fait
          quand aux risques éventuels de contenus illicites.<br /><br />
          L’utilisateur est informé que lors de ses visites sur le site
          laetitiaduchemin.fr, un ou des cookies sont susceptible de s’installer
          automatiquement sur son ordinateur. Un cookie est un fichier de petite
          taille, qui ne permet pas l’identification de l’utilisateur, mais qui
          enregistre des informations relatives à la navigation d’un ordinateur
          sur un site. Les données ainsi obtenues visent à faciliter la
          navigation ultérieure sur le site, et ont également vocation à
          permettre diverses mesures de fréquentation.<br /><br />
          Le paramétrage du logiciel de navigation permet d’informer de la
          présence de cookie et éventuellement, de refuser de la manière décrite
          à l’adresse suivante : www.cnil.fr .<br />
          Le refus d’installation d’un cookie peut entraîner l’impossibilité
          d’accéder à certains services. L’utilisateur peut toutefois configurer
          son navigateur pour refuser l’installation des cookies.
          <br /><br />
          5. Protection des biens et des personnes - gestion des données
          personnelles :
          <br /><br />
          Utilisateur : Internaute se connectant, utilisant le site susnommé :
          laetitiaduchemin.fr <br />
          En France, les données personnelles sont notamment protégées par la
          loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004,
          l'article L. 226-13 du Code pénal et la Directive Européenne du 24
          octobre 1995. <br /><br />
          Sur le site laetitiaduchemin.fr, le propriétaire du site ne collecte
          des informations personnelles relatives à l'utilisateur que pour le
          besoin de certains services proposés par le site laetitiaduchemin.fr .
          L'utilisateur fournit ces informations en toute connaissance de cause,
          notamment lorsqu'il procède par lui-même à leur saisie. Il est alors
          précisé à l'utilisateur du site laetitiaduchemin.fr l’obligation ou
          non de fournir ces informations.<br />
          Conformément aux dispositions des articles 38 et suivants de la loi
          78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux
          libertés, tout utilisateur dispose d’un droit d’accès, de
          rectification, de suppression et d’opposition aux données personnelles
          le concernant. Pour l’exercer, adressez votre demande à Laetitia
          Duchemin par email : email du webmaster ou en effectuant sa demande
          écrite et signée, accompagnée d’une copie du titre d’identité avec
          signature du titulaire de la pièce, en précisant l’adresse à laquelle
          la réponse doit être envoyée.<br /><br />
          Aucune information personnelle de l'utilisateur du site
          laetitiaduchemin.fr n'est publiée à l'insu de l'utilisateur, échangée,
          transférée, cédée ou vendue sur un support quelconque à des tiers.
          Seule l'hypothèse du rachat du site laetitiaduchemin.fr au
          propriétaire du site et de ses droits permettrait la transmission des
          dites informations à l'éventuel acquéreur qui serait à son tour tenu
          de la même obligation de conservation et de modification des données
          vis à vis de l'utilisateur du site laetitiaduchemin.fr.
          <br /><br />
          Le site laetitiaduchemin.fr est en conformité avec le RGPD .
          <br /><br />
          Les bases de données sont protégées par les dispositions de la loi du
          1er juillet 1998 transposant la directive 96/9 du 11 mars 1996
          relative à la protection juridique des bases de données.
        </p>
      </div>
      <!-- Politique de confidentialité -->
      <div id="bloc-politic">
        <h2>Politique de confidentialité</h2>
        <p>
          Laetitia Duchemin accorde une grande importance à la protection de
          votre vie privée. C’est la raison pour laquelle nous avons adopté des
          principes communs au sein de notre Politique de Confidentialité.
          <br /><br />
          Laetitia Duchemin, micro-entreprise, dont le siège social est à 286
          Boulevard Bazeilles 83000 TOULON, est responsable du traitement de vos
          données personnelles. Le présent document a pour objet de vous
          informer de la manière dont nous utilisons et protégeons vos données
          personnelles, ainsi que des raisons pour lesquelles nous traitons ces
          données.
          <br /><br />
          Il s’applique uniformément à toutes les prestations apportées par la
          société Laetitia Duchemin, étant précisé que des informations
          complémentaires pourront vous être communiquées si nécessaire dans le
          cas d’une commande particulière.
          <br /><br />
          I. QUELLES DONNÉES PERSONNELLES TRAITONS-NOUS ?
          <br /><br />
          La collecte des données personnelles se limite au strict nécessaire
          (principe de minimisation des données).
          <br /><br />
          Nous collectons et utilisons uniquement les données personnelles qui
          nous sont nécessaires dans le cadre de notre activité pour vous
          proposer des produits et services personnalisés et de qualité.
          <br /><br />
          Nous pouvons être amenés à collecter différentes catégories de données
          personnelles auprès de vous, notamment :
          <br /><br />
          - Informations d’identification et de contact (Entreprise, nom,
          prénom, adresse postale et électronique, numéro de téléphone, numéro
          de fax, sexe, diplôme, Fonction et signature) ; <br />
          - Informations nécessaires à l’expédition et la gestion de vos
          commandes (Adresse de livraison, adresse de facturation, N° de TVA
          intracommunautaire …) <br />
          - Données d’identification et d’authentification notamment lors de
          l’utilisation de nos services (logs techniques, traces informatiques,
          informations sur la sécurité, adresse IP) ;<br />
          - Données relatives aux interactions avec nous sur nos sites Internet,
          sur nos applications, sur nos pages, sur les réseaux sociaux, lors des
          entretiens et conversations téléphoniques ou visio et dans les
          courriers électroniques) ;
          <br /><br />
          Nous ne sommes en aucun cas amenés à collecter des données sensibles.
          <br /><br />
          Les données que nous utilisons peuvent être collectées directement
          auprès de vous ou obtenues des sources suivantes, dans le but de
          vérifier ou d’enrichir nos bases de données :
          <br /><br />
          - Publications/ bases de données rendues accessibles par les autorités
          officielles (Journal officiel) ;<br />
          - Prestataires de services ; <br />
          - Tiers tels que les organismes de renseignements commerciaux en
          conformité avec la réglementation en matière de protection des données
          ; <br />
          - Sites Internet/annuaires/bases de données/pages de réseaux sociaux
          contenant des informations que vous avez rendues publiques ou rendues
          publiques par des tiers.
          <br /><br />
          De plus chaque formulaire indique notamment :
          <br /><br />
          - Quels sont les objectifs du recueil de ces données (finalités) ;<br />
          - Si ces données sont obligatoires ou facultatives pour la gestion de
          votre demande ; <br />
          - Qui pourra en prendre connaissance (uniquement Laetitia Duchemin en
          principe, sauf précision dans le formulaire lorsqu’une transmission à
          un tiers est nécessaire à la gestion de votre demande) ; <br />
          - Vos droits Informatique et Libertés et la façon de les exercer
          auprès de Laetitia Duchemin.
          <br /><br />
          II. CAS PARTICULIERS DE COLLECTE DE DONNÉES, NOTAMMENT COLLECTE
          INDIRECTE
          <br /><br />
          Laetitia Duchemin peut collecter des informations vous concernant bien
          que vous ne soyez pas client. La liste ci-dessous (non exhaustive)
          constitue des exemples de catégories de données personnelles
          collectées par Laetitia Duchemin auprès de personnes non clientes,
          telles que : <br /><br />
          - Prospects, <br />
          - Donneurs d’ordres ou bénéficiaires lors de transactions faites en
          relation avec un client; <br />
          - Employés de nos prestataires, <br />
          - Réseau de distribution partenaires.
          <br /><br />
          III. POURQUOI ET SELON QUELLES BASES DE TRAITEMENT UTILISONS-NOUS VOS
          DONNÉES PERSONNELLES ?
          <br /><br />
          A. POUR NOUS CONFORMER À NOS OBLIGATIONS LÉGALES ET RÉGLEMENTAIRES
          <br />
          Nous utilisons vos données personnelles pour nous acquitter de
          différentes obligations légales et réglementaires.<br /><br />
          B. POUR EXÉCUTER UN CONTRAT CONCLU AVEC VOUS OU VOUS FOURNIR DES
          INFORMATIONS PRÉ CONTRACTUELLES <br /><br />
          Nous utilisons vos données personnelles pour conclure et exécuter nos
          prestations y compris pour : <br />
          - Vous fournir des informations relatives à nos produits et services
          ;<br />
          - Vous assister en parallèle de vos demandes ;
          <br /><br />
          Dans le cadre de la gestion de la relation client notamment :<br />
          - gestion et exécution des produits et services ;
          <br /><br />
          IV. À QUELLES CATÉGORIES D’ENTITÉS VOS DONNÉES PERSONNELLES
          POURRONT-ELLES ÊTRE DIVULGUÉES ?
          <br /><br />
          Les données personnelles recueillies dans le cadre des services
          proposés par Laetitia Duchemin sont traitées selon des protocoles
          sécurisés et permettent à Laetitia Duchemin de gérer les données
          reçues dans ses applications informatiques. Afin d’accomplir les
          finalités précitées, nous sommes amené à divulguer vos données
          personnelles uniquement aux :<br />
          - Prestataires de services et sous-traitants réalisant des prestations
          pour notre compte ; <br />
          - Mandataires indépendants, intermédiaires, <br />
          - Réseau de distribution, <br />
          - Partenaires commerciaux, <br />
          - Autorités financières, judiciaires ou agences d’État, organismes
          publics sur demande et dans la limite de ce qui est permis par la
          réglementation ; <br />
          - Certaines professions réglementées telles qu’avocats, notaires,
          commissaires aux comptes, huissiers de justice.
          <br /><br />
          V. PENDANT COMBIEN DE TEMPS CONSERVONS-NOUS VOS DONNÉES PERSONNELLES ?
          <br /><br />
          Nous conservons vos données personnelles pour la plus longue des
          durées nécessaires au respect des dispositions légales et
          réglementaires applicables ou une autre durée compte tenu des
          contraintes opérationnelles. S’agissant des clients, la majorité des
          informations sont conservées pendant la durée de la relation
          contractuelle et pendant 10 ans après la fin de la relation
          contractuelle.
          <br /><br />
          S’agissant des prospects, les informations sont conservées 3 ans à
          compter de leur collecte ou de notre dernier contact avec vous.
          <br /><br />
          VI. QUELS SONT VOS DROITS ET DE QUELLE MANIÈRE POUVEZ-VOUS LES EXERCER
          ?
          <br /><br />
          Conformément à la réglementation applicable, vous disposez de
          différents droits, à savoir :
          <br /><br />
          - Droit d’accès : vous pouvez obtenir des informations concernant le
          traitement de vos données personnelles ainsi qu’une copie de ces
          données personnelles.<br />
          - Droit de rectification : si vous estimez que vos données
          personnelles sont inexactes ou incomplètes, vous pouvez exiger que ces
          données personnelles soient modifiées en conséquence. <br />
          - Droit à l’effacement : vous pouvez exiger l’effacement de vos
          données personnelles dans la limite de ce qui est permis par la
          réglementation. <br />
          - Droit à la limitation du traitement : vous pouvez demander la
          limitation de traitement de vos données personnelles.<br />
          - Droit d’opposition : vous pouvez vous opposer au traitement de vos
          données personnelles, pour des motifs liés à votre situation
          particulière. Vous disposez du droit absolu de vous opposer au
          traitement de vos données personnelles à des fins de prospection
          commerciale, y compris le profilage lié à cette prospection.<br />
          - Droit à la portabilité de vos données, quand ce droit est
          applicable, vous avez le droit que les données personnelles que vous
          nous avez fournies vous soient rendues ou, lorsque cela est possible,
          techniquement, de les transférer à un tiers ; <br />
          - Droit de définir des directives relatives à la conservation,
          l’effacement ou la communication de vos données personnelles,
          applicables après votre décès.<br />
          - Droit de retirer votre consentement : si vous avez donné votre
          consentement au traitement de vos données personnelles, vous avez le
          droit de retirer votre consentement à tout moment.
          <br /><br />
          Pour connaître les démarches à suivre concernant l’exercice des droits
          listés ci-dessus, veuillez vous rendre sur notre page «Traitement de
          vos données personnelles». Conformément à la réglementation
          applicable, vous êtes en droit d’introduire une réclamation auprès de
          l’autorité de contrôle compétente telle que la CNIL (Commission
          nationale de l’informatique et des libertés) en France.
          <br /><br />
          VII. DE QUELLE MANIÈRE POUVEZ-VOUS PRENDRE CONNAISSANCE DES
          MODIFICATIONS APPORTÉES À CE DOCUMENT D’INFORMATION SUR LES DONNÉES
          PERSONNELLES ?
          <br /><br />
          Dans un monde en constante évolution technologique, nous actualiserons
          régulièrement ce document d’information. Nous vous invitons à prendre
          connaissance de la dernière version de ce document sur notre site
          internet et nous vous informerons de toute modification substantielle
          par le biais de nos sites ou par nos modes de communications
          habituels.
          <br /><br />
          VIII. COMMENT NOUS CONTACTER ?
          <br /><br />
          Si vous avez des questions concernant l’utilisation de vos données
          personnelles visée par le présent document, vous pouvez retrouver les
          démarches possibles sur la page «Traitement de vos données
          personnelles».
          <br /><br />
          IX. DIVERS
          <br /><br />
          Des informations relatives à notre politique cookie et à la sécurité
          informatique sont disponibles dans notre« Traitement de vos données
          personnelles »
        </p>
      </div>
      <!-- Conditions générales d'utilisation -->
      <div id="bloc-utilisation">
        <h2>Conditions générales d'utilisation</h2>
        <p>
          Conditions générales d'utilisation d'un site Internet
          <br /><br />
          Le présent document a pour objet de définir les modalités et
          conditions dans lesquelles d’une part, Laetitia Duchemin, ci-après
          dénommé laetitiaduchemin.fr, met à la disposition de ses utilisateurs
          le site, et les services disponibles sur le site et d’autre part, la
          manière par laquelle l’utilisateur accède au site et utilise ses
          services.<br />
          Toute connexion au site est subordonnée au respect des présentes
          conditions. Pour l’utilisateur, le simple accès au site de Laetitia
          Duchemin à l’adresse URL suivante laetitiaduchemin.fr implique
          l’acceptation de l’ensemble des conditions décrites ci-après.
          <br /><br />
          Propriété intellectuelle
          <br /><br />
          La structure générale du site laetitiaduchemin.fr, ainsi que les
          textes, graphiques, images, sons et vidéos la composant, sont la
          propriété de l'éditeur ou de ses partenaires. Toute représentation
          et/ou reproduction et/ou exploitation partielle ou totale des contenus
          et services proposés par le site laetitiaduchemin.fr, par quelque
          procédé que ce soit, sans l'autorisation préalable et par écrit de
          Laetitia Duchemin et/ou de ses partenaires est strictement interdite
          et serait susceptible de constituer une contrefaçon au sens des
          articles L 335-2 et suivants du Code de la propriété intellectuelle.
          <br /><br />
          <!-- La marque ____ est une marque déposées par____ .Toute représentation
          et/ou reproduction et/ou exploitation partielle ou totale de ces
          marques, de quelque nature que ce soit, est totalement prohibée.
          <br /><br /> -->
          Liens hypertextes
          <br /><br />
          Le site laetitiaduchemin.fr peut contenir des liens hypertextes vers
          d’autres sites présents sur le réseau Internet. Les liens vers ces
          autres ressources vous font quitter le site laetitiaduchemin.fr . Il
          est possible de créer un lien vers la page de présentation de ce site
          sans autorisation expresse de Laetitia Duchemin. Aucune autorisation
          ou demande d’information préalable ne peut être exigée par Laetitia
          Duchemin à l’égard d’un site qui souhaite établir un lien vers le site
          de l’éditeur. Il convient toutefois d’afficher ce site dans une
          nouvelle fenêtre du navigateur. Cependant, Laetitia Duchemin se
          réserve le droit de demander la suppression d’un lien qu’il estime non
          conforme à l’objet du site laetitiaduchemin.fr .
          <br /><br />
          Responsabilité de l’éditeur
          <br /><br />
          Les informations et/ou documents figurant sur ce site et/ou
          accessibles par ce site proviennent de sources considérées comme étant
          fiables.<br />
          Toutefois, ces informations et/ou documents sont susceptibles de
          contenir des inexactitudes techniques et des erreurs
          typographiques.<br />
          Laetitia Duchemin se réserve le droit de les corriger, dès que ces
          erreurs sont portées à sa connaissance.<br />
          Il est fortement recommandé de vérifier l’exactitude et la pertinence
          des informations et/ou documents mis à disposition sur ce site.<br />
          Les informations et/ou documents disponibles sur ce site sont
          susceptibles d’être modifiés à tout moment, et peuvent avoir fait
          l’objet de mises à jour. En particulier, ils peuvent avoir fait
          l’objet d’une mise à jour entre le moment de leur téléchargement et
          celui où l’utilisateur en prend connaissance.<br />
          L’utilisation des informations et/ou documents disponibles sur ce site
          se fait sous l’entière et seule responsabilité de l’utilisateur, qui
          assume la totalité des conséquences pouvant en découler, sans que
          Laetitia Duchemin puisse être recherché à ce titre, et sans recours
          contre ce dernier.<br />
          Laetitia Duchemin ne pourra en aucun cas être tenu responsable de tout
          dommage de quelque nature qu’il soit résultant de l’interprétation ou
          de l’utilisation des informations et/ou documents disponibles sur ce
          site.
          <br /><br />
          Accès au site
          <br /><br />
          Laetitia Duchemin s’efforce de permettre l’accès au site 24 heures sur
          24, 7 jours sur 7, sauf en cas de force majeure ou d’un événement hors
          du contrôle de Laetitia Duchemin, et sous réserve des éventuelles
          pannes et interventions de maintenance nécessaires au bon
          fonctionnement du site et des services.<br />
          Par conséquent, Laetitia Duchemin ne peut garantir une disponibilité
          du site et/ou des services, une fiabilité des transmissions et des
          performances en termes de temps de réponse ou de qualité. Il n’est
          prévu aucune assistance technique vis à vis de l’utilisateur que ce
          soit par des moyens électronique ou téléphonique.
          <br /><br />
          La responsabilité de l’éditeur ne saurait être engagée en cas
          d’impossibilité d’accès à ce site et/ou d’utilisation des services.<br />
          Par ailleurs, Laetitia Duchemin peut être amené à interrompre le site
          ou une partie des services, à tout moment sans préavis, le tout sans
          droit à indemnités. L’utilisateur reconnaît et accepte que Laetitia
          Duchemin ne soit pas responsable des interruptions, et des
          conséquences qui peuvent en découler pour l’utilisateur ou tout tiers.
          <br /><br />
          Modification des conditions d’utilisation
          <br /><br />
          Laetitia Duchemin se réserve la possibilité de modifier, à tout moment
          et sans préavis, les présentes conditions d’utilisation afin de les
          adapter aux évolutions du site et/ou de son exploitation.
          <br /><br />
          Règles d'usage d'Internet
          <br /><br />
          L’utilisateur déclare accepter les caractéristiques et les limites
          d’Internet, et notamment reconnaît que :<br />
          Laetitia Duchemin n’assume aucune responsabilité sur les services
          accessibles par Internet et n’exerce aucun contrôle de quelque forme
          que ce soit sur la nature et les caractéristiques des données qui
          pourraient transiter par l’intermédiaire de son centre serveur.<br />
          L’utilisateur reconnaît que les données circulant sur Internet ne sont
          pas protégées notamment contre les détournements éventuels. La
          présence du logo Laetitia Duchemin institue une présomption simple de
          validité. La communication de toute information jugée par
          l’utilisateur de nature sensible ou confidentielle se fait à ses
          risques et périls.<br />
          L’utilisateur reconnaît que les données circulant sur Internet peuvent
          être réglementées en termes d’usage ou être protégées par un droit de
          propriété. <br />
          L’utilisateur est seul responsable de l’usage des données qu’il
          consulte, interroge et transfère sur Internet.<br />
          L’utilisateur reconnaît que Laetitia Duchemin ne dispose d’aucun moyen
          de contrôle sur le contenu des services accessibles sur Internet.
          <br /><br />
          Droit applicable
          <br /><br />
          Tant le présent site que les modalités et conditions de son
          utilisation sont régis par le droit français, quel que soit le lieu
          d’utilisation. En cas de contestation éventuelle, et après l’échec de
          toute tentative de recherche d’une solution amiable, les tribunaux
          français seront seuls compétents pour connaître de ce litige.<br />
          Pour toute question relative aux présentes conditions d’utilisation du
          site, vous pouvez nous écrire à l’adresse suivante : 286 Boulevard
          Bazeilles 83000 TOULON .
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Legales",

  mounted() {
    document.title = "Infos-légales | Laetitia Duchemin";
  },
};
</script>

<style>
.img-confident {
  display: flex;
  width: 100%;
  object-fit: cover;
  z-index: 2;
}
.bloc-confident {
  margin: -6rem 2rem 4rem 2rem;
  position: relative;
  z-index: 1;
}
.titre-confident {
  border-radius: 20px;
  margin-bottom: 4rem;
  background-color: #fff;
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.3);
}
.titre-confident h1 {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 500;
  padding: 2rem;
  color: #f845c5;
}
.titre-confident p {
  font-size: 1.2rem;
  padding: 0 2rem 2rem 2rem;
  line-height: 30px;
}
#bloc-mentions h2,
#bloc-politic h2,
#bloc-utilisation h2 {
  font-size: 1.8rem;
  font-weight: 500;
}
#bloc-mentions p,
#bloc-politic p,
#bloc-utilisation p {
  margin: 2rem 0;
  line-height: 30px;
  font-size: 1.2rem;
}
@media screen and (min-width: 600px) {
  .img-confident {
    height: 450px;
  }
  .bloc-confident {
    margin-top: -6rem;
  }
  .titre-confident {
    margin-left: 10%;
    margin-right: 10%;
  }
}
@media screen and (min-width: 1024px) {
  .bloc-confident {
    margin-left: 10%;
    margin-right: 10%;
  }
}
</style>