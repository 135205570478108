<template>
  <section class="services">
    <img
      class="img-services hero-services"
      src="../assets/responsive.jpg"
      alt="image hero"
      width="320"
      height="300"
    />
    <div class="bloc-services">
      <div class="titre-services">
        <p>Services</p>
        <h1>
          Du site vitrine, jusqu'à l’application web en passant par la rédaction
          de contenu, je suis à votre disposition pour vous aider à réaliser
          votre projet numérique.
        </h1>
      </div>
    </div>
    <!-- VIGNETTES -->
    <div class="cards-services">
      <!-- SITE VITRINE -->
      <div class="card-services">
        <h2>Site web vitrine</h2>
        <div>
          <img
            class="img-services"
            src="../assets/onepage.jpg"
            alt="image onepage"
          />
        </div>
        <p>
          Le site web vitrine permet d'avoir une visibilité sur internet et de
          faire connaitre votre entreprise ou vos produits sur le web avec
          plusieurs pages, ou, il peut s'agir d'un landing-page ou One-Page,
          c'est-à-dire sur une seule page web. <br />
          C'est un site purement informatif.
        </p>
        <router-link to="/contact" aria-label="contact">
          <Button title="Contactez-moi" />
        </router-link>
      </div>

      <!-- APPLICATION WEB -->
      <div class="card-services">
        <h2>Application web</h2>
        <div>
          <img
            class="img-services"
            src="../assets/appweb.jpg"
            alt="image site vitrine"
          />
        </div>
        <p>
          Une application web vous permet d'interagir directement avec vos
          services. <br />
          Elle peut se présenter sous forme d'un réseau social, d'un blog, d'un
          site e-commerce ou tout autre projet avec une interaction direct avec
          les clients. C'est un site complet avec une interface utilisateur qui
          est en relation avec une base de donnée (compte, réservation, achats,
          commentaires...).
        </p>
        <router-link to="/contact" aria-label="contact">
          <Button title="Contactez-moi" />
        </router-link>
      </div>

      <!-- RÉDACTION WEB -->
      <div class="card-services">
        <h2>Rédaction web</h2>
        <div>
          <img
            class="img-services"
            src="../assets/sitevitrine.jpg"
            alt="image app web"
          />
        </div>
        <p>
          La rédaction web est un complément dans mes compétences. Il s'agit de
          créer du contenu textuel et visuel (images, vidéos...) en écrivant
          des articles pour un blog ou des fiches produits pour des sites
          e-commerce par exemple.
        </p>
        <router-link to="/contact" aria-label="contact">
          <Button title="Contactez-moi" />
        </router-link>
      </div>
    </div>
  </section>
</template>
<script>
import Button from "@/components/Button.vue";
export default {
  name: "Services",
  components: {
    Button,
  },
  mounted() {
    document.title = "SERVICES | Laetitia Duchemin";
  },
};
</script>
<style lang="scss">
.hero-services {
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.titre-services {
  border-radius: 20px;
  margin-bottom: 4rem;
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.3);
  background-color: #fff;
}
.titre-services p {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 500;
  padding-top: 2rem;
  color: #f845c5;
}
.titre-services h1 {
  font-size: 1.2rem;
  padding: 2rem;
  line-height: 30px;
}
.img-services {
  display: flex;
  width: 100%;
  object-fit: cover;
  z-index: 2;
  height: 300px;
}
.bloc-services {
  margin: -6rem 2rem 2rem 2rem;
  position: relative;
  z-index: 1;
}
.card-services {
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  margin: 1.5rem;
  padding-bottom: 1rem;
}
.card-services h2 {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  padding: 2rem 0;
}
.card-services p {
  padding: 1.2rem;
  line-height: 30px;
  font-size: 1.2rem;
}
.card-services a {
  display: flex;
  justify-content: center;
  margin: 1rem;
  animation: smooth 1.5s;
}
.bold {
  font-weight: 600;
}
@media screen and (min-width: 600px) {
  .hero-services {
    height: 450px;
  }
  .bloc-services {
    margin-top: -6rem;
    margin-bottom: 5rem;
  }
  .titre-services {
    margin-left: 10%;
    margin-right: 10%;
  }
  .cards-services {
    display: grid;
    margin: 2rem;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }
  .card-services {
    width: 100%;
    height: auto;
    margin: 0;
  }
}
@media screen and (min-width: 1024px) {
  .cards-services {
    grid-template-columns: repeat(3, 1fr);
    margin: 6rem 4rem;
  }
}
@media screen and (min-width: 1440px) {
  .cards-services {
    margin-left: 15%;
    margin-right: 15%;
  }
  .bloc-services {
    margin-left: 10%;
    margin-right: 10%;
  }
}
</style>