<template>
  <div>
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  /* name: "app", */
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
/* Reset css */

*,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
a {
  text-decoration: none;
}
a:focus {
  outline: none;
  border: 1.5px solid #f845c5;
  border-radius: 5px;
}
li {
  list-style: none;
}

body {
  font-family: "Quicksand", sans-serif;
}
</style>
