<template>
  <section class="realisations" aria-label="page realisations">
    <img
      class="img-realisation"
      src="../assets/coding-projets.jpg"
      alt="image hero"
      width="320"
      height="300"
    />
    <div class="bloc-realisation">
      <div class="titre-realisation">
        <p>Réalisations</p>
        <h1>
          Découvrez quelques-uns de mes projets web en tant que développeur web
          et rédactrice web.
        </h1>
      </div>
    </div>
    <div class="bloc-projets">
      <div class="text-presentation">
        <p>
          Je développe les sites web et les applications web avec des langages
          comme HTML, CSS, JavaScript, le framework Vue.js mais aussi Bootstrap,
          Materialize CSS pour créer le design et l'interface utilisateur.
          <br />
          Pour le back-office, j’utilise les langages comme Node.js et
          Express.js et un peu du PHP, et pour créer le serveur j'utilise MySQL
          ou MongoDB pour créer la base de donnée.
          <br />
          Je crée aussi des sites web sous WordPress.
        </p>
      </div>
      <div class="cards-realisations">
        <!-- PROJET  -->
        <div class="card-realisations">
          <div class="titre-card">
            <h2>Thème blog WordPress</h2>
            <p>Refonte et création d'un thème WordPress d'un blog</p>
          </div>
          <div class="flex-real">
            <div>
              <img
                class="img-real"
                src="../assets/wp-blog-screen.png"
                alt="image wp blog"
              />
            </div>

            <div class="div-text">
              <p>
                Refonte et redirection d'un blog Blogger vers un site WordPress.
                Création du thème de A à Z en PHP et amélioration du design avec
                Materialize CSS. Hébergement et mise en ligne du blog La quête
                du Bien-être (anciennement Bien-être By Laeti).
              </p>
              <div class="liens-ext">
                <!-- lien site -->
                <a
                  href="https://www.laquetedubienetre.fr"
                  target="_blank"
                  aria-label="site wp blog"
                  title="blog la quete"
                >
                  <fa icon="globe" />
                </a>
              </div>
            </div>
            <!-- Demo video mobile -->
            <div class="video">
              <video
                src="../assets/wp-blog.mp4"
                controls
                poster="../assets/poster-wp.png"
              ></video>
            </div>
          </div>
        </div>

        <Lines />

        <!-- PROJET 1 -->
        <div class="card-realisations">
          <div class="titre-card">
            <h2>Portfolio</h2>
            <p>One-Page de mes projets</p>
          </div>
          <div class="flex-real">
            <div>
              <img
                class="img-real"
                src="../assets/portfolio-screen.png"
                alt="image portfolio"
              />
            </div>

            <div class="div-text">
              <p>
                Mon portfolio est un One-Page en pure CSS qui permet de montrer
                mes projets en développement web, mais aussi la présentation de
                ma bio et les liens de mes contacts.
              </p>
              <div class="liens-ext">
                <!-- Github code -->
                <a
                  href="https://github.com/LaetiDuche/Portfolio"
                  target="_blank"
                  aria-label="github portfolio"
                  title="Github"
                >
                  <fa :icon="['fab', 'github']" />
                </a>
                <!-- lien site -->
                <a
                  href="https://www.portfolio.laetitiaduchemin.fr"
                  target="_blank"
                  aria-label="site portfolio"
                  title="Portfolio"
                >
                  <fa icon="globe" />
                </a>
              </div>
            </div>
            <!-- Demo video mobile -->
            <div class="video">
              <video
                src="../assets/portfolio.mp4"
                controls
                poster="../assets/poster-portfolio.png"
              ></video>
            </div>
          </div>
        </div>

        <Lines />

        <!-- PROJET 2 -->
        <div class="card-realisations">
          <div class="titre-card">
            <h2>Réseau social</h2>
            <p>
              Création d'une application web pour l'entreprise Groupomania.
              Projet d'étude.
            </p>
          </div>
          <div class="flex-real">
            <div>
              <img
                class="img-real"
                src="../assets/groupomania-screen.png"
                alt="image groupomania"
              />
            </div>
            <div class="div-text">
              <p>
                Création d'une application web de réseau social en Node.js,
                Vue.js, Bootstrap et MySQL. Possibilité de créer un compte, de
                poster des messages, d'écrire des commentaires, de modifier son
                profil et contient un compte admin pour la modération des
                messages.
              </p>
              <div class="liens-ext">
                <!-- Github code -->
                <a
                  href="https://github.com/LaetiDuche/Projet-Groupomania"
                  target="_blank"
                  aria-label="github groupomania"
                  title="Github"
                >
                  <fa :icon="['fab', 'github']" />
                </a>
                <!-- lien site page -->
                <a
                  href="https://www.portfolio.laetitiaduchemin.fr"
                  target="_blank"
                  aria-label="site portfolio"
                  title="Portfolio"
                >
                  <fa icon="globe" />
                </a>
              </div>
            </div>
            <!-- Demo video mobile -->
            <div class="video">
              <video
                src="../assets/groupomania.mp4"
                controls
                poster="../assets/poster-groupomania.png"
              ></video>
            </div>
          </div>
        </div>

        <Lines />

        <!-- PROJET 3 -->
        <div class="card-realisations">
          <div class="titre-card">
            <h2>Interface utilisateur site e-commerce</h2>
            <p>
              Création de l'interface utilisateur pour Orinoco. Projet d'étude.
            </p>
          </div>
          <div class="flex-real">
            <div>
              <img
                class="img-real"
                src="../assets/orinoco-screen.png"
                alt="image e-commerce"
              />
            </div>
            <div class="div-text">
              <p>
                Création d’une interface utilisateur pour un site e-commerce en
                HTML/CSS, Bootstrap et JavaScript. Le client choisis un produit,
                sélectionne une couleur et une quantité et l'ajoute au panier,
                puis valide sa commande avec un formulaire, et enfin reçoit un
                message de confirmation.
              </p>
              <div class="liens-ext">
                <!-- Github code -->
                <a
                  href="https://github.com/LaetiDuche/Projet-Orinoco"
                  target="_blank"
                  aria-label="github orinoco"
                  title="Github"
                >
                  <fa :icon="['fab', 'github']" />
                </a>
                <!-- lien site page -->
                <a
                  href="https://www.portfolio.laetitiaduchemin.fr"
                  target="_blank"
                  aria-label="site portfolio"
                  title="Portfolio"
                >
                  <fa icon="globe" />
                </a>
              </div>
            </div>
            <!-- Demo video mobile -->
            <div class="video">
              <video
                src="../assets/orinoco.mp4"
                controls
                poster="../assets/poster-orinoco.png"
              ></video>
            </div>
          </div>
        </div>

        <Lines />

        <!-- PROJET 4 -->
        <div class="card-realisations">
          <div class="titre-card">
            <h2>Animations et Intégration maquette</h2>
            <p>Interface utilisateur pour Ohmyfood. Projet d'étude.</p>
          </div>
          <div class="flex-real">
            <div>
              <img
                class="img-real"
                src="../assets/ohmyfood-screen.png"
                alt="image ohmyfood"
              />
            </div>
            <div class="div-text">
              <p>
                Intégration maquette design et création des animations pour l'interface d'une
                application web de réservation de menu de restaurants
                gastronomiques en HTML/CSS avec Sass. 
              </p>
              <div class="liens-ext">
                <!-- Github code -->
                <a
                  href="https://github.com/LaetiDuche/Projet-Ohmyfood"
                  target="_blank"
                  aria-label="github ohmyfood"
                  title="Github"
                >
                  <fa :icon="['fab', 'github']" />
                </a>
                <!-- lien site page -->
                <a
                  href="https://laetiduche.github.io/Projet-Ohmyfood/"
                  target="_blank"
                  aria-label="site ohmyfood"
                  title="Site Ohmyfood"
                >
                  <fa icon="globe" />
                </a>
              </div>
            </div>
            <!-- Demo video mobile -->
            <div class="video">
              <video
                src="../assets/ohmyfood.mp4"
                controls
                poster="../assets/poster-ohmyfood.png"
                type="video/mp4"
              ></video>
            </div>
          </div>
        </div>
        <!-- PROJET 5 -->
        <div class="card-realisations">
          <div class="titre-card">
            <h2>Rédaction web d'un blog</h2>
            <p>Création de contenu et d'articles, SEO</p>
          </div>
          <div class="flex-real">
            <div>
              <img
                class="img-real"
                src="../assets/blog.png"
                alt="image ohmyfood"
              />
            </div>
            <div class="div-text">
              <p>
                Rédaction de plus d'une centaine d'articles pour mon blog sur le
                thème du bien-être. Création du blog avec Blogger de Google.
              </p>
              <div class="liens-ext">
                <!-- lien site page -->
                <a
                  href="https://bienetre-bylb.blogspot.com/"
                  target="_blank"
                  aria-label="blog"
                  title="Site blog"
                >
                  <fa icon="globe" />
                </a>
              </div>
            </div>
            <!-- Demo video desktop -->
            <div class="video-desktop">
              <video
                src="../assets/blog.mp4"
                controls
                poster="../assets/poster-blog.png"
                type="video/mp4"
              ></video>
            </div>
          </div>
        </div>
      </div>
      <div class="porfolio-link">
        <a
          href="https://www.portfolio.laetitiaduchemin.fr"
          target="_blank"
          aria-label="lien portfolio"
        >
          <Button title="Voir plus sur mon portfolio" />
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import Lines from "@/components/LineSeparate.vue";
import Button from "@/components/Button.vue";
export default {
  name: "Realisations",
  components: {
    Lines,
    Button,
  },
  mounted() {
    document.title = "RÉALISATIONS | Laetitia Duchemin";
  },
};
</script>

<style lang="scss">
.img-realisation {
  display: flex;
  width: 100%;
  object-fit: cover;
  z-index: 2;
}
.bloc-realisation {
  margin: -6rem 2rem 2rem 2rem;
  position: relative;
  z-index: 1;
}
.titre-realisation {
  border-radius: 20px;
  margin-bottom: 4rem;
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.3);
  background-color: #fff;
}
.titre-realisation p {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 500;
  padding-top: 2rem;
  color: #f845c5;
}
.titre-realisation h1 {
  font-size: 1.2rem;
  padding: 2rem;
  line-height: 30px;
}
.bloc-projets {
  margin: 0 10%;
}
.cards-realisations {
  gap: 2rem;
}
.card-realisations {
  padding-bottom: 2rem;
}
.titre-card {
  margin: 3rem 0 2rem 0;
  & p {
    font-size: 1.2rem;
    margin: 1rem 0 0 0;
    line-height: 30px;
  }
}
.card-realisations h2 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 30px;
}
.div-text p {
  margin: 2rem 0;
  line-height: 30px;
  font-size: 1.2rem;
}
.liens-ext a:hover {
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
}
.text-presentation {
  justify-content: center;
  line-height: 25px;
  & h2 {
    padding: 1rem 1rem 1rem 0;
    font-weight: bold;
    font-size: 1.2rem;
  }
}
.text-presentation p {
  font-size: 1.2rem;
  line-height: 30px;
}
.liens-ext {
  display: flex;
  justify-content: space-evenly;
  margin: 2rem;
  & .fa-github,
  .fa-globe {
    margin: 0;
  }
}
.img-real {
  display: flex;
  width: 100%;
  object-fit: cover;
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
.video-desktop,
.div-text p {
  & video {
    width: 100%;
  }
}

.video,
.video-desktop,
.div-text p {
  display: flex;
  justify-content: center;
  width: 100%;
  & video {
    box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.3);
    max-height: 285px;
    border-radius: 1rem;
    border: 2px solid #7c7c7c;
    cursor: pointer;
  }
}
.porfolio-link {
  display: flex;
  justify-content: center;
  margin: 5rem 0;
}
@media screen and (min-width: 600px) {
  .bloc-projets {
    margin: 0 5%;
  }
  .titre-realisation {
    margin-left: 10%;
    margin-right: 10%;
  }
  .img-realisation {
    height: 450px;
  }
  .flex-real {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 2rem;
  }
  .div-text {
    margin: 2rem 0;
    & p {
      margin: 0;
    }
  }
  .text-presentation {
    display: flex;
    & p {
      margin: auto;
    }
  }
}
@media screen and (min-width: 769px) {
  .flex-real {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
  .flex-real-blog {
    grid-template-rows: repeat(1, 1fr);
  }
}
@media screen and (min-width: 1440px) {
  .bloc-realisation {
    margin-left: 10%;
    margin-right: 10%;
  }
}
</style>