<template>
  <div class="home">
    <!------------------- HERO ------------------>
    <section class="hero">
      <div class="hero-texte">
        <h1>
          <span class="span-0">LAETITIA DUCHEMIN</span>
          <span class="span-1">Développeur web</span>
          <span class="span-2">Création de sites internet et d'applications web à Toulon</span>
        </h1>

        <router-link to="/contact" aria-label="contact">
          <Button title="Contactez-moi" />
        </router-link>
      </div>
    </section>

    <!------------------ SERVICES ---------------->
    <section class="services">
      <!-- Formes + titre -->
      <Formes />
      <h2 class="titre1">
        <div class="services1">Services</div>
      </h2>

      <!-- Damier services GRID -->
      <div class="vignettes">
        <div class="V1">
          <router-link to="/services" aria-label="services">
            <div class="overlay">
              <h4>Site web vitrine</h4>
              <p>
                Vous souhaitez présenter vos services ou vos produits à vos
                clients.
              </p>
            </div>
          </router-link>
        </div>

        <div class="V2">
          <router-link to="/services" aria-label="services">
            <div class="overlay">
              <h4>Application web</h4>
              <p>
                L’application web vous permet de créer un réseau social, un blog
                ou tout autre projet interactif.
              </p>
            </div>
          </router-link>
        </div>

        <div class="V3">
          <router-link to="/services" aria-label="services">
            <div class="overlay">
              <h4>Rédaction web</h4>
              <p>
                Vous voulez une personne qui se charge d'écrire le contenu de
                votre site (articles, fiches produits...).
              </p>
            </div>
          </router-link>
        </div>
      </div>

      <div class="bouton">
        <router-link to="/services" aria-label="services">
          <Button title="Voir les services" />
        </router-link>
      </div>
    </section>

    <!----------------- A PROPOS ----------------------->

    <section class="apropos">
      <!-- Bio -->
      <div class="portrait">
        <div class="bloc-apropos">
          <div class="bio">
            <!-- Formes + titre -->
            <div>
              <div class="reverse">
                <Formes />
              </div>
              <h2 class="titre2">
                <div class="apropos1">A propos</div>
              </h2>
            </div>
            <div class="bloc-text">
              <p class="text-bio">
                Je crée des sites web et des applications web pour vous aider à
                réaliser votre projet digital. Mais aussi à vous aider dans la
                rédaction du contenu de votre site web.
              </p>
              <div class="bouton">
                <router-link to="/apropos" aria-label="a propos">
                  <Button title="En voir plus" />
                </router-link>
              </div>
            </div>
          </div>
          <div class="photo">
            <img
              src="../assets/portrait.png"
              alt="portrait"
              width="158"
              height="266"
            />
          </div>
        </div>
      </div>
    </section>

    <!----------------- REALISATIONS ----------------------->

    <section class="realisations">
      <!-- Formes + titre -->
      <Formes />
      <h2 class="titre3">
        <div class="real1">Réalisations</div>
      </h2>

      <div class="cases">
        <router-link to="/realisations" aria-label="realisations">
          <div class="case1">
            <div class="overlay">
              <h4>One Page d'un Porfolio</h4>
            </div>
          </div>
        </router-link>
        <router-link to="/realisations" aria-label="realisations">
          <div class="case2">
            <div class="overlay">
              <h4>Application web réseau social</h4>
            </div>
          </div>
        </router-link>
        <router-link to="/realisations" aria-label="realisations">
          <div class="case3">
            <div class="overlay">
              <h4>Interface utilisateur d'une appli web</h4>
            </div>
          </div>
        </router-link>
        <router-link to="/realisations" aria-label="realisations">
          <div class="case4">
            <div class="overlay">
              <h4>Interface utilisateur d'un site e-commerce</h4>
            </div>
          </div>
        </router-link>
      </div>

      <div class="bouton">
        <router-link to="/realisations" aria-label="realisations">
          <Button title="Voir plus de projets" />
        </router-link>
      </div>
    </section>

    <!------------------------ CONTACT ----------------------->

    <section class="contact">
      <!-- Formes + titre -->
      <div class="formes-contact">
        <div class="reverse">
          <Formes />
        </div>
        <h2 class="titre4">
          <div class="contact1">Contact</div>
        </h2>
      </div>

      <div class="background">
        <div class="text-contact">
          <h4>Vous avez un projet ?</h4>
          <div class="bouton">
            <router-link to="/contact" aria-label="contact">
              <Button title="Contactez-moi" />
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Formes from "@/components/FormesCircle.vue";
import Button from "@/components/Button.vue";
// @ is an alias to /src
export default {
  name: "Home",
  components: {
    Formes,
    Button,
  },
  mounted() {
    document.title = "ACCUEIL | Laetitia Duchemin";
  },
  data() {
    return {
      posts: [
        { id: 1, title: "Contactez-moi" },
        { id: 2, title: "Voir les services" },
      ],
    };
  },
};
</script>

<style lang="scss">
/*---------------------------------------- HERO ------------------------------------------------*/

/* MOBILE */

/* Image background */
.hero {
  display: grid;
  background: url("../assets/hero-img.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 75%;
  height: 100vh;
}
/* Texte */
.hero-texte {
  margin: 30% 1.5rem;
  height: 100vh;
  display: block;
  & h1 {
    display: grid;
  }
}
.span-0 {
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 50px;
}
.span-1 {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 2rem 0;
}
.span-2 {
  font-size: 1.2rem;
  width: 70%;
  font-weight: 400;
  padding-bottom: 3rem;
  line-height: 30px;
}

/* MENU TABLETTE / DESKTOP */

@media screen and (min-width: 426px) {
  .hero {
    margin-top: 6rem;
  }
  .hero-texte {
    margin: 20% 3rem;
  }
}
@media screen and (min-width: 769px) {
  .hero {
    margin-top: 3.5rem;
    height: 95vh;
  }
  .hero-texte {
    margin: 15% 5rem;
    & .span-2 {
      width: 100%;
    }
  }
}
@media screen and (min-width: 1026px) {
  .hero-texte {
    margin: 15% 8rem;
  }
}

/* ------------------------------------- SERVICES ----------------------------------------- */

/* MOBILE */
.titre1 {
  display: flex;
  position: relative;
  top: -8rem;
  left: 8rem;
  width: 100px;
}
.services1,
.real1,
.apropos1,
.contact1 {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
  z-index: -1;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #262626;
}

/* Vignettes + Cases */

.vignettes,
.cases {
  display: grid;
  gap: 20px;
  margin: 0 2rem;
  justify-content: center;
  & .V1,
  .V2,
  .V3,
  .case1,
  .case2,
  .case3,
  .case4 {
    background-size: cover;
    background-position: center;
    width: 80vw;
    height: 80vw;
    cursor: pointer;
    box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.3);
    border-radius: 0.2rem;
    transition: transform 1s;
    &:hover {
      transition: transform 1s;
    }
  }
  & .V1 {
    background-image: url("../assets/onepage.jpg");
  }
  & .V2 {
    background-image: url("../assets/sitevitrine.jpg");
  }
  & .V3 {
    background-image: url("../assets/appweb.jpg");
  }
}
.overlay {
  display: grid;
  width: 80vw;
  height: 80vw;
  background: rgba(0, 0, 0, 0.6);
  padding: 1rem;
  justify-content: center;
  cursor: pointer;
  border-radius: 0.2rem;
  transition: background-color 1s;
  animation: smooth 1.5s;
  &.overlay h4 {
    color: #fff;
    font-size: 1.5rem;
    font-weight: 500;
    display: grid;
    justify-content: center;
    text-align: center;
    margin: auto;
  }
  &.overlay p {
    display: grid;
    color: #fff;
    justify-content: center;
    text-align: center;
    line-height: 25px;
    font-size: 1rem;
    width: 100%;
    margin: 0;
    padding-top: 0;
  }
  &.overlay .boutonHover {
    display: none;
    animation: smooth 1.5s;
  }
}
.overlay:hover {
  display: grid;
  background: rgba(0, 0, 0, 0.8);
  transition: background-color 1.5s;
  &.overlay h4,
  &.overlay p {
    display: grid;
    animation: smooth 1.5s;
  }
  &.overlay .boutonHover {
    display: grid;
    justify-content: center;
    animation: smooth 1.5s;
  }
}
@keyframes smooth {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.bouton {
  display: grid;
  justify-content: center;
  margin: 3rem 0;
  animation: smooth 1.5s;
}

/* MENU TABLETTE / DESKTOP */

@media screen and (min-width: 426px) {
  .vignettes {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 0;
    margin: 0;
    & .V1 {
      grid-column-start: 1;
      grid-row-start: 1;
    }
    & .V2 {
      grid-column-start: 2;
      grid-row-start: 2;
    }
    & .V3 {
      grid-column-start: 1;
      grid-row-start: 3;
    }
    & .V1,
    .V2,
    .V3,
    .overlay {
      width: 50vw;
      height: 50vw;
      gap: 0;
    }
  }
}

@media screen and (min-width: 700px) {
  .vignettes {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    & .V1 {
      grid-column-start: 2;
      grid-row-start: 1;
    }
    & .V2 {
      grid-column-end: 1;
      grid-row-start: 2;
    }
    & .V3 {
      grid-column-start: 3;
      grid-row-start: 2;
    }
    & .V1,
    .V2,
    .V3,
    .overlay {
      width: 33.4vw;
      height: 33.4vw;
    }
  }
}
@media screen and (min-width: 1024px) {
  .vignettes {
    margin: 0 20%;
    & .V1,
    .V2,
    .V3,
    .overlay {
      width: 25.2vw;
      height: 25.3vw;
    }
  }
  .overlay {
    padding: 2rem;
    &.overlay h4,
    .overlay:hover.overlay h4 {
      font-size: 1.7rem;
    }
    &.overlay p {
      display: none;
    }
    &.overlay:hover.overlay p {
      font-size: 1.2rem;
    }
  }
  .overlay p {
    display: none;
  }
}

/* ----------------------------------- A PROPOS ------------------------------------- */

.reverse {
  transform: scale(-1, 1);
  padding: 0;
  margin: 0;
}
.titre2 {
  display: flex;
  position: relative;
  top: -8rem;
  right: 5rem;
  width: 150px;
  margin: 0;
  float: right;
}

.bio {
  display: grid;
  justify-content: center;
  text-align: center;
}
.bloc-text {
  width: 100%;
  z-index: 1;
  &.bouton {
    order: 2;
  }
}
.text-bio {
  display: flex;
  margin: 2rem;
  justify-content: center;
  text-align: center;
  font-size: 1.2rem;
  line-height: 2rem;
  order: 1;
}
.photo {
  background: #f5f5f5;
  clip-path: polygon(0% 0%, 60% 0%, 100% 100%, 0% 100%);
  order: 2;
  & img {
    position: relative;
    top: 0.5rem;
    margin: 3rem 0 0 5rem;
    padding: 0;
    object-fit: cover;
  }
}

@media screen and (min-width: 600px) {
  .portrait {
    width: 100%;
    & .bloc-apropos {
      display: flex;
    }
    & .text-bio {
      margin-left: 0;
    }
    & .bio,
    .photo {
      width: 50%;
    }
    & .photo {
      display: flex;
      order: 1;
      & img {
        margin-top: 15rem;
      }
    }
    & .bio {
      order: 2;
    }
  }
}
@media screen and (min-width: 769px) {
  .photo {
    & img {
      width: 258px;
      height: 466px;
      margin: 11rem auto 0 auto;
    }
  }
}

/* ------------------------------------------- REALISATIONS ------------------------------------- */
.realisations {
  overflow: hidden;
}
.titre3 {
  display: flex;
  position: relative;
  top: -8rem;
  left: 7.5rem;
  width: 100px;
}
.case1,
.case2,
.case3,
.case4 {
  filter: grayscale(100%);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border: 1px solid #000000;
  &:hover {
    filter: grayscale(0%);
    transform: scale(1.03);
  }
}
.case1 {
  background-image: url("../assets/portfolio.png");
}
.case2 {
  background-image: url("../assets/groupomania.png");
}
.case3 {
  background-image: url("../assets/ohmyfood.png");
}
.case4 {
  background-image: url("../assets/orinoco.png");
}
.case1 .overlay h4,
.case2 .overlay h4,
.case3 .overlay h4,
.case4 .overlay h4 {
  animation: smooth 1.5s;
  color: none;
}

.case2 .overlay h4,
.case4 .overlay h4 {
  color: #262626;
}
.case1 .overlay h4,
.case3 .overlay h4,
.case2:hover .overlay h4,
.case4:hover .overlay h4 {
  color: #fff;
}
.case1 .overlay,
.case3 .overlay,
.case2 .overlay,
.case4 .overlay {
  background: rgba(0, 0, 0, 0);
  padding: 0 0.3rem;
}
.case1:hover .overlay,
.case3:hover .overlay,
.case2:hover .overlay,
.case4:hover .overlay {
  display: flex;
  background: rgba(0, 0, 0, 0.8);
  height: 22vw;
  &.overlay h4 {
    display: grid;
    font-size: 1.1rem;
    justify-content: center;
    text-align: center;
    padding: 0;
    animation: smooth 1.5s;
  }
}
.case1 .overlay,
.case3 .overlay {
  background: rgba(0, 0, 0, 0.6);
}
.case2 .overlay,
.case4 .overlay {
  background: rgba(255, 255, 255, 0.5);
}

@media screen and (min-width: 426px) {
  .cases {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 0.7rem;
    & .case3 .overlay {
      background: rgba(255, 255, 255, 0.5);
    }
    & .case4 .overlay {
      background: rgba(0, 0, 0, 0.6);
    }
    .case3:hover .overlay,
    .case4:hover .overlay {
      background: rgba(0, 0, 0, 0.8);
    }
    & .case1,
    .case2,
    .case3,
    .case4,
    .overlay {
      gap: 0.5rem;
      width: 45vw;
      height: 45vw;
    }
    & .case1:hover .overlay,
    .case3:hover .overlay,
    .case2:hover .overlay,
    .case4:hover .overlay {
      width: 45vw;
      height: 15vw;
      padding: 0;
    }
  }
  .case2 .overlay h4,
  .case3 .overlay h4 {
    color: #262626;
  }
  .case2:hover .overlay h4,
  .case3:hover .overlay h4,
  .case4 .overlay h4 {
    color: #fff;
  }
}
@media screen and (min-width: 700px) {
  .cases {
    margin: 0 20%;
    & .case1,
    .case2,
    .case3,
    .case4,
    .overlay {
      gap: 0.5rem;
      width: 35vw;
      height: 35vw;
    }
    & .case1:hover .overlay,
    .case3:hover .overlay,
    .case2:hover .overlay,
    .case4:hover .overlay {
      width: 45vw;
      height: 12vw;
      padding: 0;
    }
  }
}
@media screen and (min-width: 1024px) {
  .cases {
    margin: 0 22%;
    & .case1,
    .case2,
    .case3,
    .case4,
    .overlay {
      gap: 0.5rem;
      width: 27.3vw;
      height: 27.3vw;
    }
    & .case1:hover .overlay,
    .case3:hover .overlay,
    .case2:hover .overlay,
    .case4:hover .overlay {
      height: 10vw;
    }
  }
  .case1 .overlay h4,
  .case2 .overlay h4,
  .case3 .overlay h4,
  .case4 .overlay h4 {
    display: none;
  }
}

/* ------------------------------------- CONTACT ----------------------------------------- */
.reverse {
  top: 2rem;
  position: relative;
}
.titre4 {
  display: flex;
  position: relative;
  top: -8rem;
  right: 5rem;
  width: 150px;
  float: right;
}
.background {
  background: url("../assets/contact.jpg");
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  height: 90vh;
  width: 100%;
}
.text-contact {
  flex-direction: column;
  position: relative;
  margin: auto;
  padding-left: 6rem;
  & h4 {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 1.2rem;
  }
}
.text-contact .bouton {
  margin: 1rem;
}
</style>
