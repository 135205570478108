import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faGlobe, faPhone, faAt, faLocationDot, faClose  } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons'


library.add(faGlobe, faGithub,faLinkedin,faFacebook, faPhone, faAt, faLocationDot, faClose )

createApp(App).use(router).component('fa', FontAwesomeIcon).mount('#app');
