<template>
  <footer class="footer1">
    <div class="footer">
      <div class="bloc-F1">
        <h3>Laetitia Duchemin</h3>
        <p class="liens-f2">
          Laetitia Duchemin, développeur web et rédactrice web à Toulon.
          Création de sites internets et d'applications web.
        </p>
      </div>
      <div class="bloc-F2">
        <h3>Plan du site</h3>
        <ul>
          <li>
            <router-link to="/" class="liens-f">Accueil</router-link>
          </li>
          <li>
            <router-link to="/services" class="liens-f">Services</router-link>
          </li>
          <li>
            <router-link to="/apropos" class="liens-f">A propos</router-link>
          </li>
          <li>
            <router-link to="/realisations" class="liens-f"
              >Réalisations</router-link
            >
          </li>
          <li>
            <router-link to="/contact" class="liens-f">Contact</router-link>
          </li>
          <li>
            <router-link to="/info-legales" class="liens-f"
              >Infos légales</router-link
            >
          </li>
        </ul>
      </div>
      <div class="bloc-F3">
        <h3>Me suivre</h3>
        <ul>
          <li>
            <a
              href="https://www.linkedin.com/in/laetitia-duchemin/"
              target="_blank"
              class="liens-f"
              >Linkedin</a
            >
          </li>
          <li>
            <a
              href="https://www.facebook.com/laetitia.duchemin.dev/"
              target="_blank"
              class="liens-f"
              >Facebook
            </a>
          </li>
          <!-- <li>
            <a
              href="https://www.malt.fr/profile/laetitiaduchemin"
              target="_blank"
              class="liens-f"
              >Malt
            </a>
          </li> -->
        </ul>
      </div>
    </div>
    <div class="droits">
      <p>Laetitia Duchemin © {{ currentDate() }} - Tous droits réservés</p>
    </div>
    <div>
      <component
        src="https://efreecode.com/js.js"
        id="eXF-prold83-0"
        defer
        async
        :is="'script'"
      ></component>
    </div>
  </footer>
</template>


<script>
export default {
  methods: {
    currentDate() {
      const current = new Date();
      const date = `${current.getFullYear()}`;
      return date;
    },
  },
};

window.axeptioSettings = {
  clientId: "65be8c15b917cbb2649ab022",
  cookiesVersion: "laetitiaduchemin-fr-EU-2",
};
(function (d, s) {
  var t = d.getElementsByTagName(s)[0],
    e = d.createElement(s);
  e.async = true;
  e.src = "//static.axept.io/sdk.js";
  t.parentNode.insertBefore(e, t);
})(document, "script");
</script> 



<style lang="scss">
.footer1,
.footer,
.droits {
  background-color: #262626;
}
.footer {
  padding: 2rem;
}
.bloc-F1,
.bloc-F2,
.bloc-F3,
.bloc-F4,
.bloc-F5 {
  color: #fff;
  padding: 0.5rem;
  margin: 1rem auto;
}

.bloc-F1 h3,
.bloc-F2 h3,
.bloc-F3 h3,
.bloc-F4 h3 {
  font-size: 1.5rem;
  line-height: 18px;
  margin-bottom: 1rem;
}
.bloc-F1 h3 {
  line-height: 25px;
}
.liens-f,
.liens-f2 {
  color: #fff;
  font-size: 1.1rem;
}
.liens-f2 {
  line-height: 25px;
}
.liens-f {
  line-height: 30px;
}
.liens-f:hover {
  color: #f845c5;
}
.droits {
  display: flex;
  color: #fff;
  padding: 0 2rem 2rem 2rem;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
}

@media screen and (min-width: 426px) {
  .footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .bloc-F1 {
    max-width: 30%;
  }
  .bloc-F4 {
    color: #fff;
    padding: 0.5rem;
    margin: 1rem 0 1rem 2rem;
  }
}
</style>