<template>
  <header>
    <!-- MENU MOBILE -->
    <div class="nav-logo">
      <input type="checkbox" class="nav-check" id="check" />
      <label for="check" class="btn-menu" aria-label="menu">
        <i class="fa-solid fa-caret-left"></i>
        <span class="logo">LD</span>
      </label>
      <ul class="nav-list">
        <li>
          <router-link to="/" class="liens" aria-label="accueil"
            >Accueil</router-link
          >
        </li>
        <li>
          <router-link to="/services" class="liens" aria-label="services"
            >Services</router-link
          >
        </li>
        <li>
          <router-link
            to="/realisations"
            class="liens"
            aria-label="realisations"
            >Réalisations</router-link
          >
        </li>
        <li>
          <router-link to="/apropos" class="liens" aria-label="apropos"
            >A propos</router-link
          >
        </li>

        <li>
          <router-link to="/contact" class="liens" aria-label="contact"
            >Contact</router-link
          >
        </li>
      </ul>
    </div>

    <!-- MENU TABLETTE / PC -->

    <div class="nav-large">
      <router-link to="/" class="logo-large">LAETITIA DUCHEMIN</router-link>
      <nav class="nav2">
        <ul class="nav-list2">
          <li>
            <router-link
              to="/services"
              class="liens-large"
              aria-label="services"
              >Services</router-link
            >
          </li>
          <li>
            <router-link
              to="/realisations"
              class="liens-large"
              aria-label="realisations"
              >Réalisations</router-link
            >
          </li>
          <li>
            <router-link to="/apropos" class="liens-large" aria-label="apropos"
              >A propos</router-link
            >
          </li>
          
          <li>
            <router-link to="/contact" class="liens-large" aria-label="contact"
              >Contact</router-link
            >
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: "services",
  props: ["title"],
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>


<style lang="scss">
/* MENU MOBILE */

.nav-check,
.nav-large {
  display: none;
}
.btn-menu {
  background-color: #ffff;
  height: 4rem;
  width: 4rem;
  position: fixed;
  top: 1rem;
  right: 1rem;
  border-radius: 50%;
  z-index: 1000;
  text-align: center;
  cursor: pointer;
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.3);
}

.btn-menu:hover {
  background-color: #262626;
  color: #ffff;
}
.fa-caret-left {
  color: #fff;
  font-size: 1.3rem;
  -webkit-text-fill-color: #262626;
  background-clip: text;
  -webkit-text-stroke: 0.8px #fff;
  position: fixed;
  top: 2.5rem;
  right: 5rem;
}
.logo {
  position: relative;
  display: inline-block;
  margin-top: 1rem;
  font-size: 1.5rem;
}
.nav-list {
  display: grid;
  transform: scale(1, 0);
  transform-origin: top;
  transition: transform 500ms ease-in;
  z-index: 900;
  height: 100vh;
  text-align: center;
  background-color: #262626;
  position: fixed;
  align-content: center;
  left: 0;
  right: 0;
}
.liens {
  color: #fff;
  display: inline-block;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin: 0.5rem;
  padding: 0.5rem;
}
.liens:hover {
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
  color: #f845c5;
}
a.liens-large.active {
  color: #f845c5;
}
.nav-check:checked ~ .nav-list {
  transform: scale(1.1);
}

/* MENU TABLETTE / DESKTOP */

@media screen and (min-width: 426px) {
  .nav-logo {
    display: none;
  }
  .nav-large {
    display: grid;
    background: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0rem 0rem 0.4rem rgba(0, 0, 0, 0.1);
  }
  .logo-large {
    color: #262626;
    padding: 1.5rem;
    margin: auto;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
  }
  .logo-large:hover {
    cursor: pointer;
  }
  .nav2 {
    color: #262626;
    font-weight: 400;
    text-align: center;
    margin: auto;
    padding-bottom: 1rem;
  }
  .nav-list2 {
    display: flex;
  }
  .liens-large {
    display: flex;
    color: #262626;
    font-size: 1.2rem;
    padding: 0 1rem;
    transition: all 0.2s ease-in-out;
  }
  .liens-large:hover {
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
    font-weight: 400;
    color: #f845c5;
  }
}
@media screen and (min-width: 796px) {
  .nav-large {
    display: flex;
  }
  .logo-large {
    padding-left: 0.5rem;
  }
  .nav2 {
    padding: 1.2rem 0;
  }
}
</style>