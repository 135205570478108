<template>
  <div class="success">
    <div>
      <h1>Merci pour votre message !</h1>
    </div>
    <div class="bouton">
      <router-link to="/">
        <Button
          title="Retour à l'accueil"
          type="submit"
          id="btn-envoyer"
          value="envoyer"
        />
      </router-link>
    </div>
  </div>
</template>
<script>
import Button from "@/components/Button.vue";

export default {
  name: "Success",
  mounted() {  
    document.title = "MERCI | Laetitia Duchemin";  
  },
  components: {
    Button,
  },
  data() {
    return {
      posts: [{ id: 1, title: "Retour à l'accueil" }],
    };
  },
};
</script>

<style lang="scss">
.success {
  display: grid;
  position: relative;
  top: 8rem;
  margin: 0% 10% 100% 10%;
  background-color: #f5f5f5;
  border-radius: 20px;
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.3);
}
.success h1{
  text-align: center;
  padding: 2rem;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 40px;
}
.success .bouton{
  margin: 1rem 1rem 3rem 1rem;
}

@media screen and (min-width: 426px){
  .success{
    top: 12rem;
  }
}

</style>