<template>
  <button>{{title}}</button>
</template>

<script>
export default {
  props: ['title']
}
</script>

<style>
/* Bouton */
button {
  padding: 0.5rem 1rem;
  background-color: #f845c5;
  border-radius: 30px;
  border: none;
  color: #fff;
  cursor: pointer;
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.3);
  font-weight: 600;
  transition: all ease-in-out 0.3s;
}
button:hover {
  transform: scale(1.1);
  transition: all ease-in-out 0.3s;
  color: #fff;
}
button:focus{
  outline: none;
  border: 1.5px solid #000;
}
</style>