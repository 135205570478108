import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Services from '../views/Services.vue'
import Apropos from '../views/Apropos.vue'
import Realisations from '../views/Realisations.vue'
import Contact from '../views/Contact.vue'
import Success from '../views/SuccessForm.vue'
import Legales from '../views/Legales.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/apropos',
    name: 'apropos',
    component: Apropos
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/realisations',
    name: 'Realisations',
    component: Realisations
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/success',
    name: 'Success',
    component: Success
  },
  {
    path: '/info-legales',
    name: 'Legales',
    component: Legales
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active'
})

export default router
