<template>
  <div class="formes">
    <div class="black-circle"></div>
    <div class="white-circle"></div>
    <div class="bb-circle"></div>
  </div>
</template>

<style lang="scss">
.formes{
    margin: 1rem 0 0 1rem;
  }
.black-circle {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  background: #262626;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  top: 1rem;
  left: 1rem;
  z-index: -1;
}
.white-circle {
  width: 68px;
  height: 68px;
  border-radius: 50px;
  border: 1px solid #f5f5f5;
  background: transparent;
  position: relative;
  top: -1rem;
  left: 3rem;
}
.bb-circle {
  width: 17px;
  height: 17px;
  border-radius: 50px;
  background: #262626;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  top: -2.5rem;
  left: 6.2rem;
  /* -moz-animation: circle 4s linear infinite;
  animation: circle 4s linear infinite; */
}
/* @keyframes circle{
  0%{
    transform: rotate(0deg)translate(-35px)rotate(0deg);
  }
  100%{
    transform: rotate(360deg)translate(-35px)rotate(-360deg);
  }
} */
.titre {
  display: flex;
  padding-top: 0.5rem;
  padding-left: 2rem;
  width: 100%;
}
</style>