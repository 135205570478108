<template>
  <section class="contact">
    <img
      class="img-contact"
      src="../assets/contact-page.jpg"
      alt="image hero"
      width="220"
      height="300"
    />
    <div class="bloc-contact">
      <div class="titre-contact">
        <p>Contact</p>
        <h1>
          Je suis à votre disposition pour travailler avec vous et mener à bien
          votre projet.
        </h1>
      </div>
      
      <!-- CONTACT + LOCALISATION -->
      <div class="geo-contact">
        <div class="bloc-fa-solid">
          <div class="follow">
            <h2>Me suivre</h2>
            <span class="logo">
              <a
                href="https://www.linkedin.com/in/laetitia-duchemin/"
                target="_blank"
                aria-label="linkedin"
                title="Linkedin"
              >
                <fa :icon="['fab', 'linkedin']" class="linkedin-contact" />
              </a>
              <a
                href="https://www.facebook.com/laetitia.duchemin.dev/"
                target="_blank"
                aria-label="facebook"
                title="Facebook"
              >
                <fa :icon="['fab', 'facebook']" class="facebook-contact" />
              </a>
            </span>
          </div>
          <h2>Me contacter</h2>
          <div class="mail">
            <fa icon="at" />
            <a href="mailto:contact@laetitiaduchemin.fr">contact@laetitiaduchemin.fr</a>
          </div>
          <div class="tel">
            <fa icon="phone" />
            <a href="tel:0680178054">06 80 17 80 54</a>
          </div>
          <div class="lieu">
            <fa icon="location-dot" />
            <p>Toulon, Var(83) France</p>
          </div>
        </div>

        <a
          target="_blank"
          aria-label="carte toulon"
          class="map link-map"
          href="https://www.google.com/maps/place/Le+Mourillon,+83000+Toulon/@43.1122225,5.9319628,2446m/data=!3m1!1e3!4m5!3m4!1s0x12c91b799385be35:0x1446d80e4ad55bcf!8m2!3d43.1074299!4d5.9381856"
        >
          <img
            class="img-carte"
            src="../assets/carte-toulon.png"
            alt="carte toulon"
            width="600"
            height="600"
          />
        </a>
      </div>
      <!-- FORMULAIRE -->
      <div class="formulaire">
        <form
          action="https://formsubmit.co/45b6909e3a46c7662c3b55677fce3e9f" 
          method="POST"
        >
          <input
            type="hidden"
            name="_next"
            value="https://www.laetitiaduchemin.fr/#/success"
          />
          <input type="hidden" name="_template" value="basic" />
          <input
            type="hidden"
            name="_subject"
            value="Nouveau message client !"
          />
          <!-- NAME -->
          <div>
            <label for="name" class="form-label"></label>
            <input
              class="form-input"
              type="text"
              name="name"
              id="nom"
              placeholder="Nom"
              required
            />
          </div>

          <!-- EMAIL -->
          <div>
            <label for="email" class="form-label"></label>
            <input
              class="form-input"
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              required
            />
          </div>

          <!-- SUJET -->
          <div>
            <label for="sujet" class="form-label"></label>
            <input
              class="form-input"
              type="text"
              name="subject"
              id="sujet"
              placeholder="Sujet"
              required
            />
          </div>

          <!-- MESSAGE -->
          <div>
            <label for="message" class="form-label"></label>
            <textarea
              rows="8"
              cols="33"
              class="form-input input-message"
              type="text"
              name="message"
              id="message"
              placeholder="Votre message"
              required
            ></textarea>
          </div>

          <div class="bouton">
            <Button
              title="Envoyer"
              type="submit"
              id="btn-envoyer"
              value="envoyer"
            />
          </div>
        </form>
      </div>

    </div>
  </section>
</template>

<script>
import Button from "@/components/Button.vue";

export default {
  name: "Contact",
  components: {
    Button,
  },
  mounted() {
    document.title = "CONTACT | Laetitia Duchemin";
  },
  data() {
    return {
      posts: [{ id: 1, title: "Envoyer" }],
    };
  },
};
</script>

<style lang="scss">
/* INTRO CONTACT */
.img-contact {
  display: flex;
  width: 100%;
  object-fit: cover;
  z-index: 2;
}
.bloc-contact {
  margin: -6rem 2rem 2rem 2rem;
  position: relative;
  z-index: 1;
}
.titre-contact {
  border-radius: 20px;
  margin-bottom: 4rem;
  background-color: #fff;
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.3);
}
.titre-contact p {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 500;
  padding-top: 2rem;
  color: #f845c5;
}
.titre-contact h1 {
  font-size: 1.2rem;
  padding: 2rem;
  line-height: 30px;
}
.formulaire,
.form-input {
  border-radius: 5px;
}

.form-input,
input,
textarea,
.form-label {
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::placeholder {
  color: #262626;
}
.titre-text {
  & h1,
  .text {
    justify-content: center;
  }
  & h1 {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 500;
    padding-top: 2rem;
    color: #f845c5;
  }
  & .text {
    font-size: 1.2rem;
    padding: 2rem;
    line-height: 30px;
  }
}

/* FORMULAIRE */
.formulaire {
  margin: 4rem 0.5rem 2rem 0.5rem;
  background-color: #fff;
}
.form-input {
  width: 100%;
  padding: 1rem;
  margin: 0.5rem 0 0.5rem 0;
}
form .bouton {
  margin: 1rem 0;
}

/* FOLLOW */
.follow {
  margin-bottom: 2rem;
}
h2 {
  font-size: 1.3rem;
  font-weight: 600;
}
.fa-github,
.fa-phone,
.fa-at,
.fa-location-dot,
.fa-globe {
  color: #f845c5;
  font-size: 1.5rem;
  margin: 1rem 0;
}
.logo {
  margin: 1rem 0;
}
.linkedin-contact,
.facebook-contact {
  color: #f845c5;
  font-size: 2rem;
  padding: 0;
  margin-right: 1rem;
}
.linkedin-contact:hover,
.facebook-contact:hover,
.link-malt2:hover {
  transform: scale(1.1);
  transition: all ease-in-out 0.1s;
}

/* GEO-CONTACT */
.bloc-fa-solid {
  margin: 4rem 0 2rem 0;
}
.tel,
.mail,
.lieu {
  display: flex;
}
.tel a,
.lieu p,
.mail a {
  margin: auto 0;
  padding-left: 1rem;
  color: #000;
}
.tel a:hover, 
.mail a:hover{
  color: #f845c5;
}

.img-carte {
  display: flex;
  object-fit: cover;
  width: 100%;
  height: auto;
  filter: grayscale(100%);
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.3);
  &:hover {
    filter: grayscale(0%);
    transform: scale(1.03);
    transition: all 0.2s ease-in-out;
  }
}

/* FOCUS */
input:focus,
textarea:focus,
.link-map:focus {
  outline: none;
  border: 1.5px solid #f845c5;
}

/* RESPONSIVE */
@media screen and (min-width: 600px) {
  .img-contact {
    height: 450px;
  }
  .bloc-contact {
    margin-top: -6rem;
  }
  .titre-contact {
    margin-left: 10%;
    margin-right: 10%;
  }
  .formulaire {
    margin-left: 20%;
    margin-right: 20%;
  }
  .geo-contact {
    display: flex;
    margin-top: 5rem;
  }
  .bloc-fa-solid,
  .map {
    width: 50%;
  }
  .bloc-fa-solid {
    justify-content: center;
    margin: 1rem 0;
  }
}
@media screen and (min-width: 1025px) {
  .formulaire {
    margin-left: 25%;
    margin-right: 25%;
  }
  .geo-contact {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 10%;
  }
}
@media screen and (min-width: 1440px) {
  .bloc-contact {
    margin-left: 10%;
    margin-right: 10%;
  }
}
</style>