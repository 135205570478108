<template>
  <div class="separate">
    <hr class="line">
  </div>
</template>

<style lang="scss">
.separate{
  margin: 3rem 0;
}
hr{
  border: 0;
  margin: 40px 0;
}
.line {
	/* border-top: 1px solid #094CFA; */
  border: 0;
  height: 2px;
  background-image: linear-gradient(to right, transparent, #262626, transparent);  
}
</style>